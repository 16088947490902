<template>
  <div class="apply-servers" v-if="content">
    <van-nav-bar
      :title="content ? content.title : ''"
      fixed
      z-index="999"
      placeholder
      style="width: 100%"
    >
      <template #left>
        <!-- <van-icon name="arrow-left" color="#000" @click="onClickLeft" /> -->
        <img
          src="../../../assets/other/back.png"
          style="width: 12px; height: 24px"
          @click="onClickLeft"
          alt=""
        />
      </template>
    </van-nav-bar>

    <div
      class="content-box"
      v-for="(item1, i) in content.jsonData"
      :key="item1.title"
    >
      <template v-if="i == 0">
        <div class="first-box">
          <div class="header-box">
            <img class="header-image" :src="item1.images[0]" alt="" />
            <span class="header-title">{{ item1.title }}</span>
          </div>
          <div class="first-text">
            {{ item1.content }}
          </div>
        </div>
      </template>

      <template v-else>
        <div class="title-box" v-if="item1.title">
          <span class="iconfont icon-home_left"></span>
          <span class="title">{{ item1.title }}</span>
          <span class="iconfont icon-home_right"></span>
        </div>
        <img
          class="img"
          v-for="image in item1.images"
          :key="image"
          :src="image"
        />
        <span
          class="content-text"
          :style="
            item1.images.length > 0 ? 'margin-top: 12px;' : 'margin-top: 0px;'
          "
        >
          {{ item1.content }}
        </span>
      </template>
    </div>

    <!-- <div class="text" v-if="content">{{ content.content }}</div> -->
  </div>
</template>

<script>
import { Icon, NavBar } from "vant";
import { Template_Api } from "@/api/home";
import { getLocalStorage } from "@/core/utils/local-storage";
import { parse } from "qs";

export default {
  components: {
    [NavBar.name]: NavBar,
    [Icon.name]: Icon,
  },

  data() {
    return {
      content: null,
      jsonData: null,
    };
  },

  created() {
    this.getImageList();
  },
  methods: {
    getImageList() {
      let query = parse(getLocalStorage("query"));
      this.$reqGet(Template_Api + "2", {
        ...query,
      }).then((res) => {
        if (res.map.items.length > 0) {
          this.content = res.map.items[0];
        }
      });
    },

    onClickLeft() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less">
.apply-servers {
  width: 100%;
  .text {
    width: 343px;
    margin: 12px auto 12px auto;
    font-size: 14px;
    color: #333;
    white-space: pre-line;
  }
  .content-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 13px;
    margin-bottom: 12px;
    .first-box {
      width: 343px;
      background: #ffffff;
      box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 13px;

      .header-box {
        display: flex;
        align-items: center;
        margin: 20px 0;
        .header-image {
          width: 90px;
          height: 90px;
          border-radius: 50px;
          margin: 0 20px;
          object-fit: cover;
        }
        .header-title {
          font-size: 13px;
          color: #666;
          flex: 1;
          margin-right: 20px;
        }
      }
      .first-text {
        font-size: 13px;
        color: #666;
        // text-align: center;
        white-space: pre-line;
        width: 303px;
      }
    }

    .title-box {
      display: flex;
      align-items: center;
      width: 343px;
      justify-content: center;
      margin-bottom: 8px;

      .title {
        font-size: 16px;
        color: var(--theme-color);
        margin: 0 16px;
      }
      .iconfont {
        font-size: 20px;
      }
    }
    .img {
      width: 343px;
      // height: 172px;
      object-fit: cover;
    }
    .content-text {
      font-size: 14px;
      color: #333333;
      margin-top: 12px;
      text-align: center;
      white-space: pre-line;
      width: 343px;
    }
  }
  .imgs-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    .image {
      width: 100%;
    }
  }
}
</style>
